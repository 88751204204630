import './dynamic-imports';

import {setCookie, getCookie, deleteCookie} from './helpers';
import {scrollToElement} from './helpers';

window.setCookie = setCookie;
window.getCookie = getCookie;
window.deleteCookie = deleteCookie;
window.scrollToElement = scrollToElement;

document.addEventListener('DOMContentLoaded', function () {
    const urlAnchor = window.location.hash;
    if (urlAnchor) {
        const anchor = document.querySelector(urlAnchor);
        if (anchor) {
            setTimeout(function () {
                scrollToElement(anchor, 250);
            }, 500);
        }
    }
});

document.querySelector('.js-guesty-search-widget-trigger').addEventListener('click', function (e) {
    if (window.location.pathname === '/') {
        e.preventDefault();
        scrollToElement("#guesty-search-widget", 250);
    }
});
