import {initInView} from "./helpers";

document.querySelectorAll('[data-layout="header"]').forEach($el => {
    import('../../../partials/layout/header/header' /* webpackChunkName: "/dist/js/header" */).then(({default: init}) => init($el));
});

document.querySelectorAll('[data-layout="menu-mobile"]').forEach($el => {
    import('../../../partials/layout/menu-mobile/menu-mobile' /* webpackChunkName: "/dist/js/menu-mobile" */).then(({default: init}) => init($el));
});

document.querySelectorAll('[data-request="onShowPopup"], .js-popup-trigger, [data-fancybox]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/popup/popup' /* webpackChunkName: "/dist/js/popup" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="slider"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/slider/slider' /* webpackChunkName: "/dist/js/slider" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="accordion"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/accordion/accordion' /* webpackChunkName: "/dist/js/accordion" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('.js-loader').forEach($el => {
    import('../../../partials/blocks/loader/loader' /* webpackChunkName: "/dist/js/loader" */).then(({default: init}) => init($el));
});

document.querySelectorAll('[data-block="form-contact"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/form-contact/form-contact' /* webpackChunkName: "/dist/js/form-contact" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-js="carousel-testimonials"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/carousel/carousel-testimonials' /* webpackChunkName: "/dist/js/carousel-testimonials" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-js="carousel-featured-listings"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/carousel/carousel-featured-listings' /* webpackChunkName: "/dist/js/carousel-featured-listings" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="google-map"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/google-map/google-map' /* webpackChunkName: "/dist/js/google-map" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-block="gallery"]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/gallery/gallery' /* webpackChunkName: "/dist/js/gallery" */).then(({default: init}) => init($el));
    });
});

document.querySelectorAll('[data-aos]').forEach($el => {
    initInView($el).then(() => {
        import('../../../partials/blocks/list-advantages/list-advantages' /* webpackChunkName: "/dist/js/list-advantages" */).then(({default: init}) => init($el));
    });
});